/* eslint-disable no-param-reassign */
/* eslint-disable no-else-return */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import tradly from 'tradly';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { getCookies, setCookie, deleteCookie, getCookie } from 'cookies-next';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';

export const signIn = createAsyncThunk(
  'auth/signIn',

  async ({ prams }, thunkAPI) => {
    try {
      const response = await axios.post('/api/auth/sign_in', { prams });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const signInByAuthKey = createAsyncThunk(
  'auth/signInByAuthKey',

  async ({ auth_key, refresh_key }, thunkAPI) => {
    try {
      const response = await axios.get('/api/auth/profile', {
        params: { auth_key },
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const socialSignIn = createAsyncThunk(
  'auth/socialSignIn',

  async ({ prams }, thunkAPI) => {
    try {
      const response = await axios.post('/api/auth/social_sign_in', { prams });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const refreshPage = createAsyncThunk(
  'auth/refreshPage',

  async ({}, thunkAPI) => {
    try {
      const client_host_name = window.location.host; //window.location.host
      const auth_key = getCookie(`${client_host_name}_auth_key`);
      const refresh_key = getCookie(`${client_host_name}_refresh_key`);
      const decodedAuthKey = auth_key ? jwtDecode(auth_key) : null;
      // if available in cookies

      if (
        auth_key !== '' &&
        refresh_key !== '' &&
        auth_key &&
        refresh_key &&
        decodedAuthKey?.exp &&
        decodedAuthKey?.exp - moment().unix() > 10
      ) {
        return thunkAPI.fulfillWithValue({
          user: {
            key: {
              auth_key,
              refresh_key,
            },
          },
        });
      } else if (refresh_key !== '' && refresh_key) {
        // if not auth key available in cookies
        const response = await axios.post('/api/auth/refresh', {
          key: refresh_key,
        });
        const { data } = await response;
        if (!response.data.error) {
          return data;
        } else {
          const { error } = await response.data;
          return error;
        }
      } else {
        // if both not auth key and refresh key not available in cookies
        return thunkAPI.rejectWithValue({ code: 401, message: 'unset' });
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const signUp = createAsyncThunk(
  'auth/signUp',

  async ({ prams }, thunkAPI) => {
    try {
      const response = await axios.post('/api/auth/sign_up', { prams });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const verifyUser = createAsyncThunk(
  'auth/verifyUser',

  async ({ prams }, thunkAPI) => {
    try {
      const response = await axios.post('/api/auth/verify_user', { prams });

      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const verifyUserEmail = createAsyncThunk(
  'auth/verifyUserEmail',

  async ({ prams }, thunkAPI) => {
    try {
      const response = await axios.post('/api/auth/forgot_password', { prams });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const UserInfo = createAsyncThunk(
  'auth/UserInfo',

  async ({ userId, auth_key }, thunkAPI) => {
    try {
      const response = await axios.get('/api/user/user_info', {
        params: { userID: userId },
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isFetching: false,
    isSocialLoginFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    verifyId: '',
    login: false,
    user_email: '',
    first_name: '',
    last_name: '',
    auth_key: '',
    refresh_key: '',
    profile_pic: '',
    user_details: '',
    countries_list: [],
    tenant_countries_list: [],
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = '';
      return state;
    },
    logout: (state, { payload }) => {
      state.login = false;
      state.user_email = '';
      state.first_name = '';
      state.last_name = '';
      state.auth_key = '';
      TYPE_CONSTANT.AUTH_KEY = '';
      state.refresh_key = '';
      state.profile_pic = '';
      state.user_details = '';
      localStorage.clear();
      deleteCookie(`${TYPE_CONSTANT.DOMAIN}_auth_key`);
      deleteCookie(`${TYPE_CONSTANT.DOMAIN}_refresh_key`);
      deleteCookie(`${TYPE_CONSTANT.DOMAIN}_isStoreAccount`);
      deleteCookie(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`);
      deleteCookie(`${TYPE_CONSTANT.DOMAIN}_storeAccountCategoryID`);
      deleteCookie(`${TYPE_CONSTANT.DOMAIN}_login`);
      return state;
    },
    setCountries: (state, { payload }) => {
      state.countries_list = payload?.countries;
      return state;
    },
    setUserLoginByIntegration: (state, { payload }) => {
      {
        const expirationDate = new Date(new Date().getTime() + 899 * 1000);
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.login = true;
        state.errorMessage = '';
        state.user_email = payload?.user?.email;
        state.first_name = payload?.user?.first_name;
        state.last_name = payload?.user?.last_name;
        state.profile_pic = payload?.user?.profile_pic;
        state.auth_key = payload?.user?.key.auth_key;
        TYPE_CONSTANT.AUTH_KEY = payload?.user?.key.auth_key;
        state.refresh_key = payload?.user?.key.refresh_key;
        state.user_details = payload?.user;
        localStorage.setItem(
          `${TYPE_CONSTANT.DOMAIN}_auth_key`,
          payload?.user?.key.auth_key
        );
        setCookie(
          `${TYPE_CONSTANT.DOMAIN}_auth_key`,
          payload?.user?.key.auth_key,
          { maxAge: 36000 }
        );
        setCookie(
          `${TYPE_CONSTANT.DOMAIN}_refresh_key`,
          payload?.user?.key.refresh_key
        );
        localStorage.setItem(
          `${TYPE_CONSTANT.DOMAIN}_refresh_key`,
          payload?.user?.key.refresh_key
        );
        setCookie(`${TYPE_CONSTANT.DOMAIN}_login`, true);
        localStorage.setItem('user_details', JSON.stringify(payload?.user));
        localStorage.setItem('expiration_time', expirationDate);
        localStorage.setItem('login', true);
      }
      return state;
    },
    setTenantCountries: (state, { payload }) => {
      state.tenant_countries_list = payload?.countries;
      return state;
    },
  },
  extraReducers: {
    //* sign in
    [signIn.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        const expirationDate = new Date(new Date().getTime() + 899 * 1000);
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.login = true;
        state.errorMessage = '';
        state.user_email = payload?.user?.email;
        state.first_name = payload?.user?.first_name;
        state.last_name = payload?.user?.last_name;
        state.profile_pic = payload?.user?.profile_pic;
        state.auth_key = payload?.user?.key.auth_key;
        TYPE_CONSTANT.AUTH_KEY = payload?.user?.key.auth_key;
        state.refresh_key = payload?.user?.key.refresh_key;
        state.user_details = payload?.user;
        localStorage.setItem(
          `${TYPE_CONSTANT.DOMAIN}_auth_key`,
          payload?.user?.key.auth_key
        );
        setCookie(
          `${TYPE_CONSTANT.DOMAIN}_auth_key`,
          payload?.user?.key.auth_key,
          { maxAge: 36000 }
        );
        setCookie(
          `${TYPE_CONSTANT.DOMAIN}_refresh_key`,
          payload?.user?.key.refresh_key
        );
        localStorage.setItem(
          `${TYPE_CONSTANT.DOMAIN}_refresh_key`,
          payload?.user?.key.refresh_key
        );
        setCookie(`${TYPE_CONSTANT.DOMAIN}_login`, true);
        localStorage.setItem('user_details', JSON.stringify(payload?.user));
        localStorage.setItem('expiration_time', expirationDate);
        localStorage.setItem('login', true);
      }
    },
    [signIn.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [signIn.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    //* sign in by auth key
    [signInByAuthKey.fulfilled]: (state, { payload, meta }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        const expirationDate = new Date(new Date().getTime() + 899 * 1000);
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.login = true;
        state.errorMessage = '';
        state.user_email = payload?.user?.email;
        state.first_name = payload?.user?.first_name;
        state.last_name = payload?.user?.last_name;
        state.profile_pic = payload?.user?.profile_pic;
        state.auth_key = meta?.arg?.auth_key;
        TYPE_CONSTANT.AUTH_KEY = meta?.arg?.auth_key;
        state.refresh_key = meta?.arg?.refresh_key;
        state.user_details = payload?.user;
        localStorage.setItem(
          `${TYPE_CONSTANT.DOMAIN}_auth_key`,
          meta?.arg?.auth_key
        );
        setCookie(`${TYPE_CONSTANT.DOMAIN}_auth_key`, meta?.arg?.auth_key, {
          maxAge: 36000,
        });
        setCookie(
          `${TYPE_CONSTANT.DOMAIN}_refresh_key`,
          meta?.arg?.refresh_key
        );
        localStorage.setItem(
          `${TYPE_CONSTANT.DOMAIN}_refresh_key`,
          meta?.arg?.refresh_key
        );
        setCookie(`${TYPE_CONSTANT.DOMAIN}_login`, true);
        localStorage.setItem('user_details', JSON.stringify(payload?.user));
        localStorage.setItem('expiration_time', expirationDate);
        localStorage.setItem('login', true);
      }
    },
    [signInByAuthKey.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [signInByAuthKey.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },

    // * social signin
    [socialSignIn.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isSocialLoginFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        const expirationDate = new Date(new Date().getTime() + 899 * 1000);
        state.isError = false;
        state.isSocialLoginFetching = false;
        state.isSuccess = true;
        state.login = true;
        state.errorMessage = '';
        state.user_email = payload?.user?.email;
        state.first_name = payload?.user?.first_name;
        state.last_name = payload?.user?.last_name;
        state.profile_pic = payload?.user?.profile_pic;
        state.auth_key = payload?.user?.key.auth_key;
        TYPE_CONSTANT.AUTH_KEY = payload?.user?.key.auth_key;
        state.refresh_key = payload?.user?.key.refresh_key;
        state.user_details = payload?.user;
        setCookie(
          `${TYPE_CONSTANT.DOMAIN}_auth_key`,
          payload?.user?.key.auth_key,
          { maxAge: 36000 }
        );
        setCookie(
          `${TYPE_CONSTANT.DOMAIN}_refresh_key`,
          payload?.user?.key.refresh_key
        );
        localStorage.setItem(
          `${TYPE_CONSTANT.DOMAIN}_refresh_key`,
          payload?.user?.key.refresh_key
        );
        setCookie(`${TYPE_CONSTANT.DOMAIN}_login`, true);
        localStorage.setItem('user_details', JSON.stringify(payload?.user));
        localStorage.setItem('expiration_time', expirationDate);
        localStorage.setItem('login', true);
      }
    },
    [socialSignIn.pending]: (state) => {
      state.isSocialLoginFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [socialSignIn.rejected]: (state, { payload }) => {
      state.isSocialLoginFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },

    // * refresh page
    [refreshPage.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
      } else {
        const userDetails = JSON.parse(localStorage.getItem('user_details'));
        state.login = true;
        state.user_email = userDetails?.email;
        state.first_name = userDetails?.first_name;
        state.last_name = userDetails?.last_name;
        state.profile_pic = userDetails?.profile_pic;
        state.auth_key = payload?.user?.key.auth_key;
        TYPE_CONSTANT.AUTH_KEY = payload?.user?.key.auth_key;
        state.refresh_key = payload?.user?.key.refresh_key;
        state.user_details = userDetails;
        localStorage.setItem(
          `${TYPE_CONSTANT.DOMAIN}_auth_key`,
          payload?.user?.key.auth_key
        );
        setCookie(
          `${TYPE_CONSTANT.DOMAIN}_auth_key`,
          payload?.user?.key.auth_key,
          {
            maxAge: 36000,
          }
        );
        setCookie(
          `${TYPE_CONSTANT.DOMAIN}_refresh_key`,
          payload?.user?.key.refresh_key
        );
        localStorage.setItem(
          `${TYPE_CONSTANT.DOMAIN}_refresh_key`,
          payload?.user?.key.refresh_key
        );
        setCookie(`${TYPE_CONSTANT.DOMAIN}_login`, true);
        localStorage.setItem('login', true);
      }
    },
    [refreshPage.pending]: (state) => {
      state.isError = false;
      state.errorMessage = '';
    },
    [refreshPage.rejected]: (state, { payload }) => {
      state.login = false;
      state.user_email = '';
      state.first_name = '';
      state.last_name = '';
      state.auth_key = '';
      TYPE_CONSTANT.AUTH_KEY = '';
      state.refresh_key = '';
      state.profile_pic = '';
      state.user_details = '';
      deleteCookie(`${TYPE_CONSTANT.DOMAIN}_auth_key`);
      deleteCookie(`${TYPE_CONSTANT.DOMAIN}_refresh_key`);
      deleteCookie(`${TYPE_CONSTANT.DOMAIN}_isStoreAccount`);
      deleteCookie(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`);
      deleteCookie(`${TYPE_CONSTANT.DOMAIN}_storeAccountCategoryID`);
      deleteCookie(`${TYPE_CONSTANT.DOMAIN}_login`);
      localStorage.removeItem('login');
      localStorage.removeItem('user_details');
      localStorage.removeItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`);
      localStorage.removeItem(`${TYPE_CONSTANT.DOMAIN}_refresh_key`);
      if (payload?.message !== 'unset') {
        localStorage.clear();
      }
      return state;
    },

    //* sign up
    [signUp.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.verifyId = payload.verify_id;
        setCookie(
          `${TYPE_CONSTANT.DOMAIN}_new_user_verify_id`,
          payload.verify_id
        );
      }
    },
    [signUp.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [signUp.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },

    // * verification of user
    [verifyUser.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        const expirationDate = new Date(new Date().getTime() + 899 * 1000);
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.login = true;
        state.errorMessage = '';
        state.user_email = payload?.user?.email;
        state.first_name = payload?.user?.first_name;
        state.last_name = payload?.user?.last_name;
        state.profile_pic = payload?.user?.profile_pic;
        state.auth_key = payload?.user?.key.auth_key;
        TYPE_CONSTANT.AUTH_KEY = payload?.user?.key.auth_key;
        state.refresh_key = payload?.user?.key.refresh_key;
        state.user_details = payload?.user;
        localStorage.setItem(
          `${TYPE_CONSTANT.DOMAIN}_auth_key`,
          payload?.user?.key.auth_key
        );
        setCookie(
          `${TYPE_CONSTANT.DOMAIN}_auth_key`,
          payload?.user?.key.auth_key,
          { maxAge: 36000 }
        );
        setCookie(
          `${TYPE_CONSTANT.DOMAIN}_refresh_key`,
          payload?.user?.key.refresh_key
        );
        localStorage.setItem(
          `${TYPE_CONSTANT.DOMAIN}_refresh_key`,
          payload?.user?.key.refresh_key
        );
        setCookie(`${TYPE_CONSTANT.DOMAIN}_login`, true);
        localStorage.setItem('user_details', JSON.stringify(payload?.user));
        localStorage.setItem('expiration_time', expirationDate);
        localStorage.setItem('login', true);
      }
    },
    [verifyUser.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [verifyUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },

    // * verify user mail
    [verifyUserEmail.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
      }
    },
    [verifyUserEmail.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [verifyUserEmail.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },

    // * user info
    [UserInfo.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.user_email = payload?.user?.email;
        state.first_name = payload?.user?.first_name;
        state.last_name = payload?.user?.last_name;
        state.profile_pic = payload?.user?.profile_pic;
        state.user_details = payload?.user;
        localStorage.setItem('user_details', JSON.stringify(payload?.user));
      }
    },
    [UserInfo.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [UserInfo.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
  },
});

export const {
  clearState,
  logout,
  setCountries,
  setTenantCountries,
  setUserLoginByIntegration,
} = authSlice.actions;
export const authSelector = (state) => state.auth;
